import React from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import Logo from "./logo"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Button, Link } from "gatsby-theme-material-ui"
import Grid from "@material-ui/core/Grid"
import { useLocation } from "@reach/router"
import { menu } from "../config"

const useStyles = makeStyles(theme => ({
  logo: { width: 250, [theme.breakpoints.down("md")]: { width: 180 } },
  toolbar: {
    [theme.breakpoints.down("md")]: { paddingLeft: 0 },
  },

  menu: {
    flexGrow: 1,
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButton: {
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appbar: { background: theme.palette.common.white },
}))

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = ({ siteTitle, ...props }) => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = React.useState(false)

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Link to="/">
              <Logo className={classes.logo} />
            </Link>
            <Menu classes={classes} />
            <IconButton
              edge="end"
              className={classes.menuButton}
              aria-label="menu"
              onClick={() => setMenuOpen(prev => !prev)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <MobileMenu
        open={menuOpen}
        anchor="bottom"
        onClose={() => setMenuOpen(false)}
        classes={classes}
      />
    </>
  )
}

const useLinkStyles = makeStyles(theme => ({
  link: ({ active }) => ({
    color: active ? theme.palette.primary.main : "inherit",
  }),
}))
function Menu({ classes }) {
  return (
    <Box className={classes.menu}>
      <Grid direction="row" container justify="flex-end" spacing={3}>
        {menu.map(([to, label]) => (
          <Grid item key={to}>
            <MenuLink to={to}>{label}</MenuLink>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

function MenuLink({ to, children }) {
  const { pathname } = useLocation()
  const classes = useLinkStyles({ active: to === pathname })
  return (
    <Button className={classes.link} to={to}>
      {children}
    </Button>
  )
}

function MobileMenu({ open, anchor, onClose }) {
  return (
    <Drawer open={open} anchor={anchor} onClose={onClose}>
      <List>
        <ListItem>
          <MenuLink to="/">Home</MenuLink>
        </ListItem>
        {menu.map(([to, label]) => (
          <ListItem key={to}>
            <MenuLink to={to}>{label}</MenuLink>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}

export default Header
