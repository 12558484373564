export const menu = [
  ["/services", "Services"],
  ["/testimonials", "Testimonials"],
  ["/gallery", "Gallery"],
  ["/contact-us", "Contact Us"],
]

export const headerHeight = "5rem"
export const contentPaddingY = 18
export const licence = "299746C"
export const ABN = "61 613 020 983"
