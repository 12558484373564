import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Container from "@material-ui/core/Container"
import Header from "./header"
import BgImg from "gatsby-background-image"
import { makeStyles } from "@material-ui/core"
import { headerHeight, licence, ABN } from "../config"
import Typography from "@material-ui/core/Typography"
import { Link, Button } from "gatsby-theme-material-ui"
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt"
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  bg: props => ({
    textTransform: "uppercase",
    backgroundPosition: props.backgroundPosition || "center",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: headerHeight,
    color: theme.palette.common.white,
    width: "100%",
  }),
  footer: ({ pathname }) => ({
    bottom: 0,
    top: "auto",
    marginTop: pathname?.includes("contact-us") ? theme.spacing(12) : 0,
  }),
  actionbar: {
    bottom: 0,
    top: "auto",
    marginTop: theme.spacing(12),
    color: theme.palette.common.white,
    padding: `0 ${theme.spacing(4)}`,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  actionbarContent: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    "& > *": {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  separator: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  footerContent: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  container: {
    minHeight: "40vh",
  },
}))
const Layout = ({ children, bgImage, title, backgroundPosition }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { pathname } = useLocation()
  const classes = useStyles({ backgroundPosition, pathname })
  const bgStack = [bgImage]
  if (Boolean(title)) {
    bgStack.unshift(
      `linear-gradient(
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)
            )`
    )
  }
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} height={headerHeight} />
      <CssBaseline />
      <Box>
        {Boolean(bgImage) && (
          <BgImg fluid={bgStack} className={classes.bg}>
            <Typography variant="h2">{title}</Typography>
          </BgImg>
        )}
        <Container maxWidth="md" className={classes.container}>
          {children}
        </Container>
        {!pathname.includes("contact-us") && (
          <AppBar
            color="secondary"
            className={classes.actionbar}
            position="relative"
          >
            <Box className={classes.actionbarContent}>
              <Link color="inherit" href="/contact-us">
                <Typography variant="h5">Get in touch today</Typography>
              </Link>
              <Button
                to="/contact-us"
                variant="contained"
                color="primary"
                size="large"
                endIcon={<ArrowRightAlt></ArrowRightAlt>}
              >
                Start a conversation{" "}
              </Button>
            </Box>
          </AppBar>
        )}
        <AppBar color="primary" className={classes.footer} position="relative">
          <Box className={classes.footerContent}>
            <Typography>
              © {new Date().getFullYear()}
              {` `}
              <Link color="inherit" href="https://www.coastlineroofing.com.au">
                Coastline Roofing Solutions
              </Link>
            </Typography>
            <Separator />
            <Typography>Wollongong and the South Coast</Typography>
            <Separator />
            <Typography>Lic. No: {licence}</Typography>
            <Separator />
            <Typography>ABN: {ABN}</Typography>
          </Box>
        </AppBar>
      </Box>
    </>
  )
}

export default Layout

function Separator() {
  const classes = useStyles()
  return (
    <Box px={3} className={classes.separator}>
      <Typography>|</Typography>
    </Box>
  )
}
